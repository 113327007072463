// libs
import Image from "next/image";
import React from "react";
import Slider from "react-slick";
// const
import { WHAT_HAVE_TO_SAY } from "src/constants/dynamic";
// hooks
import { useAppSelector } from "src/hooks";
// redux
import { dynamicStateFieldSelector } from "src/redux/slices";
// types
import { IClinicReviews, IDynamicWhatHaveToSay } from "src/types/dynamic/clinics/whatHaveToSay";
// utils
import sliderDiscriptionsClinics from "src/utils/sliderDescriptionsClinics";
// styling
import { FlexColumn } from "src/components/ui/common";
import {
  ImageContainer,
  IconBubble,
  SliderContainer,
  Section,
  TestimonialContainer,
  LinkOrButtonContainer,
  PlayBubble,
  LinkLabel,
} from "./Testimonial.styles";
import { colors } from "src/theme";
import { LargeTitle, Title, Body } from "src/theme/typography";

interface ITestimonialsData extends IClinicReviews {
  avatarUrl?: string;
  linkUrl: string;
  videoUrl?: string;
}

const Testimonial = () => {
  const settings = sliderDiscriptionsClinics();

  const data = useAppSelector(dynamicStateFieldSelector<IDynamicWhatHaveToSay>(WHAT_HAVE_TO_SAY));
  const description: Array<IClinicReviews> = data?.clinic_reviews || [];

  const testimonialsData = [
    {
      ...description[0],
      avatarUrl: "/images/customers/caroline-pennard.png",
      linkUrl: "/case-studies/how-petsapp-helped-pennard-vets-reduce-missed-calls-by-85percent",
      videoUrl: "https://www.youtube.com/watch?v=1m-frRA-aAU",
    },
    {
      ...description[1],
      avatarUrl: "/images/customers/ellie-castle-vets.png",
      linkUrl: "/case-studies/castle-vets-case-study",
    },
    {
      ...description[2],
      avatarUrl: "/images/customers/sian-the-vet.png",
      linkUrl: "/case-studies/how-the-vet-extended-their-veterinary-clinic-digitally-using-petsapp",
    },
  ];

  return (
    <Section>
      <Title textColor={colors.white} style={{ textAlign: "center" }}>
        {data?.title}
      </Title>
      <SliderContainer>
        <Slider {...settings}>
          {testimonialsData.map((item: ITestimonialsData) => (
            <div key={item?.id} style={{ width: "100%" }}>
              <TestimonialContainer>
                <ImageContainer>
                  <LinkOrButtonContainer as="a" href={item.linkUrl}>
                    <PlayBubble
                      src={`icons/${item.videoUrl ? "play_arrow" : "read_more"}.svg`}
                      style={{ borderRadius: 400 }}
                    />
                    <LinkLabel>{item.videoUrl ? "Watch" : "Read"} case study</LinkLabel>
                  </LinkOrButtonContainer>
                  <Image
                    width={300}
                    height={300}
                    src={item.avatarUrl || "/images/customers/fallback.png"}
                    loader={({ src }) => src}
                    layout="responsive"
                  />
                </ImageContainer>
                <FlexColumn gap="12px">
                  <IconBubble src="icons/format_quote.svg" />
                  <LargeTitle textColor={colors.white}>{item?.text}</LargeTitle>
                  <Body textColor={colors.mainLight}>&mdash; {item?.person}</Body>
                </FlexColumn>
              </TestimonialContainer>
            </div>
          ))}
        </Slider>
      </SliderContainer>
    </Section>
  );
};

export default Testimonial;
