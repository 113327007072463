import styled from "styled-components";
import device from "src/constants/media";
import { colors } from "src/theme";

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  gap: 48px;
  max-width: 1400px;
  margin-inline: 24px;
  margin-top: 80px;

  @media ${device.laptopS} {
    margin-top: 160px;
    margin-inline: auto;
  }
`;
export const ImageContainer = styled.div`
  width: 160px;
  height: 160px;
  margin-bottom: 32px;
  position: relative;

  img {
    border-radius: 8px;
  }

  @media ${device.laptop} {
    width: 100%;
    height: 100%;
    margin-bottom: 0;
  }
`;
export const LinkOrButtonContainer = styled.div`
  bottom: -28px;
  display: flex;
  place-items: center;
  flex-direction: column;
  gap: 8px;
  left: -72px;
  position: absolute;
  z-index: 1;

  @media ${device.laptop} {
    left: -56px;
    bottom: -28px;
  }

  :hover {
    img {
      background-color: ${colors.orangeDark};
    }
  }
`;
export const PlayBubble = styled.img`
  background-color: ${colors.orange};
  width: 64px;
  height: 64px;
  padding: 12px;

  @media ${device.laptop} {
    width: 120px;
    height: 120px;
    padding: 28px;
  }
`;
export const LinkLabel = styled.span`
  background-color: ${colors.orangeLight};
  border-radius: 4px;
  padding: 8px 12px;
  text-align: center;
  color: ${colors.greyDark};
`;
export const IconBubble = styled.img`
  background-color: ${colors.mainDark};
  border-radius: 200px;
  width: 40px;
  height: 40px;
  padding: 8px;
`;
export const SliderContainer = styled.div`
  min-width: 272px;
  li {
    width: 100%;
  }
  li.slick-active div {
    background-color: white !important;
  }
  .slick-list {
    overflow: visible;
  }
`;

export const TestimonialContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;
  place-items: center;
  margin-inline: 12px;

  @media ${device.laptop} {
    grid-template-columns: 1fr 2fr;
    gap: 40px;
    margin-inline: 152px;
  }
`;
