import React from "react";
import { colors } from "src/theme";
import styled from "styled-components";

const DotsContainer = styled.ul`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 20px;
  max-width: 1096px;
  margin-inline: auto;
  position: relative;
`;

const Dots = (dots: any) => <DotsContainer>{dots}</DotsContainer>;

const StyledPaging = styled.div`
  width: 100%;
  height: 4px;
  background-color: ${colors.mainDark};
  border-radius: 100px;
  margin-top: 60px;
`;

const Paging = () => <StyledPaging />;

const sliderDiscriptionsClinics = () => {
  const settings = {
    appendDots: Dots,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 10000,
    customPaging: Paging,
    dots: true,
    infinite: true,
    pauseOnDotsHover: true,
    pauseOnHover: true,
    slidesToScroll: 1,
    slidesToShow: 1,
    speed: 1000,
  };

  return settings;
};

export default sliderDiscriptionsClinics;
